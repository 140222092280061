import React, { Component } from 'react';
import { Button, Container, Collapse, Navbar, NavbarToggler, Row, Col, Nav, NavItem, NavLink, NavbarBrand } from 'reactstrap';
import CookieConsent from "react-cookie-consent";

import logo from './images/logo.png';
import aaa from './images/Index_Upratovanie.jpg';
import neptun from './images/neptunsolutions.png';
import contact from './images/pageContact.jpg';

import './css/App.css';

import { FaPhone, FaEnvelope, FaMapMarker } from 'react-icons/fa';

var translations = require('./lang/sk.json');

// import { withScriptjs, withGoogleMap, GoogleMap, Marker, } from "react-google-maps";

// const MyMapComponent = withScriptjs(withGoogleMap((props) =>
//   <GoogleMap
//     defaultZoom={8}
//     defaultCenter={{ lat: 48.193166, lng: 17.038588 }}
//   >
//     {props.isMarkerShown && <Marker position={{ lat: 48.193166, lng: 17.038588 }} />}
//   </GoogleMap>
// ));

class App extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <div className="App">
      <Navbar color="white" light expand="md" sticky="top">
          <NavbarBrand href="#home">
            <img src={logo} alt="logo" />
          </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink onClick={() => {this.toggle()}} href="#onas">O nás</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => {this.toggle()}} href="#sluzby">Služby</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => {this.toggle()}} href="#kontakt">Kontakt</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
        <CookieConsent location="bottom" buttonText={translations.p11.Text12}
            cookieName="siteCookie" style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }} expires={150}
        >
          {translations.p11.Text11}
        </CookieConsent>
        <Container>
          <Row>
            <Col md="12">
              <img src={aaa} className="col-md-12" alt="logo" />
            </Col>
          </Row>
          <Row id="onas" name="onas" className='mainpart'>
            <Col md="12" className="featurette">
              <h2 className="featurette-heading">O nás</h2>
              <div className="lead">
Vítame Vás na stránke spoločnosti <b>POIEL & MARS, s. r. o.</b>, ( UPRATOVANIE.online ) naším cieľom je poskytovať profesionálne upratovacie služby pre Vašu spoločnosť alebo domácnosť. 
<br /><br />
Keďže vieme, že každý klient môže mať rôzne požiadavky, preto Vám vieme pripraviť ponuku šitú podľa vašich predstáv a potrieb.
<br />
V prípade záujmu, neváhajte a kontaktujte nás. Na základe osobného stretnutia a obhliadky objektu Vám radi vypracujeme cenovú ponuku.
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col md="12">
              <div className="rotated">Novinka</div>
              <h2 className="featurette-heading">SERVIS OKIEN</h2>
              <div className="lead">
Poskytujeme pozáručný servis a nastavenie, vrátane opravy kovania plastových, devených a hliníkových okien. 
              </div>
            </Col>
          </Row> */}
          <Row id="sluzby" name="sluzby" className='mainpart'>
            <Col md="12">
              <div className="rotated">Novinka</div>
              <h2 className="featurette-heading">Umývanie Fasád</h2>
              <div className="lead">
                <br />
                <h4>Prečo umývať fasádu?</h4>
                Hlavným dôvodom je vaše zdravie! Dôležitou skutočnosťou však je, že spóry plesní sa pri vetraní môžu dostať z exteriéru do interiéru, teda obytných častí domu. Pri plesniach napadnutého domu sa vyhotovil mykologický rozbor omietky, pri ktorom sa zistila vysoká koncentrácia plesní a prítomnosť ďalších mikroorganizmov – t.j. baktérií a kvasiniek vo vysokých koncentráciách.
                <br /><br />
                Na základe týchto rozborov akreditovaným laboratóriom sa zistila inhalácia až 5000 spór na 1 m2, ktorá môže u citlivých jedincov spôsobiť alergické onemocnenia.

                <br /><br />
                <h4>Vaše zdravie!</h4>
                Sme si toho plne vedomí, preto pri čistení fasád používame účinné prípravky s fungicídnymi prísadami, ktoré dokážu zlikvidovať plesne a škodlivé mikroorganizmy, čo znamená, že aplikáciou týchto prípravkov pri umývaní dokážeme uviesť fasádu do pôvodného, „zdravého“ stavu.
                
                <br /><br />
                <h4>Bývajte v čistom a krásnom dome!</h4>
                Organické a atmosférické nečistoty vytvárajú na povrchu objektov povlak, na odstránenie ktorého voda nestačí, preto pri umývaní a čistení fasády používame špeciálne čistiace prípravky, ktoré sú určené na všetky typy fasád. Fasáda by sa mala čistiť každých 2 až 5 rokov podľa množstva exhalátov, prachu poletujúceho v ovzduší a stupňa znečistenia.
                
                <br /><br />
                <h4>Pozor na poškodenie fasády</h4>
                Ak farba na fasáde, ktorá jediná chráni zatepľovací systém, je už z väčšej miery napadnutá, stráca svoje ochranné vlastnosti a po vyčistení <b>treba fasádu natrieť</b>. POZOR na rozdielne postupy pri čistení fasád firmami, ktoré používajú technológiu príliš vysokého tlaku a horúcej vody. V závislosti od stavu fasády môže dôjsť k poškodeniu fasády.
                
                <br /><br />
                <h4>Aké technologické postupy používame pri čistení fasád?</h4>
                Na napadnutú fasádu nanesieme fungicídne čistiace prostriedky, ktoré necháme pôsobiť cca 24 Hod. Po uplynutí tejto doby sú riasy a plesne zlikvidované (sčernejú a sú mŕtve). Následne fasádu vyčistíme vysokým tlakom vody. Voda odstráni zvyšnú mastnotu a špinu na fasáde. Tým sa vyčistená fasáda zneutralizuje. Dávame záruku až 5 rokov. Záruka je odstupňovaná podľa veku fasády.
                
                <br /><br />
                <h4>Prečo si vybrať na čistenie fasády práve nás?</h4>
                <ul>
<li>Umývanie fasád, čistenie fasád, nátery fasád a čistenie a nátery striech sú našou hlavnou činnosťou, preto vám ručíme za kvalitu práce na najvyššej možnej úrovni!</li>
<li>Pracujeme pomocou horolezeckej techniky</li>
<li>Sme profesionáli v tejto oblasti – dôkladné vyčistenie a čistota na pracovisku sú pre nás základom!</li>
</ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <h2 className="featurette-heading">Náter Fasád</h2>
              <div className="lead">
              Farba, ktorá je na povrchu fasády, má životnosť 12 až 15 rokov. Počas tejto doby na ňu pôsobí UV žiarenie, poveternostné a prírodné vplyvy (výskyt rias a plesní), čím dochádza k jej opotrebeniu. Z našich skúseností vieme, že po uplynutí tejto doby treba vo väčšine prípadov aplikovať rekonštrukčný náter pre znovuobnovenie funkcie fasády. Ak farba obsiahnutá v omietke alebo fasádna farba prestáva plniť svoju funkciu, začínajú sa najmä na zateplených objektoch objavovať mikropraskliny, ktoré začínajú prepúšťať viac vlhkosti a vody do zatepľovacieho systému. Tak môže vzniknúť jeho nenávratné poškodenie.
              <br /><br /><ol>
<li>Dôkladné čistenie fasády chemickou cestou a nízkym tlakom ‒ pozri v kategórií umývanie fasád</li>
<li>Dôkladné zakrytie všetkých dverí, okien, podhľadov, žľabov, zvodov atď.</li>
<li>V prípade nutnosti oprava drobných prasklín, dier od vtákov, mechanických poškodení...</li>
<li>Aplikácia penetračného náteru z dôvodu:
<ul>
<li>zjednotenia rôznorodej nasiakavosti podkladov</li>
<li>zvýšenia priľnavosti farby</li>
<li>dlhšej životnosti celkového náteru</li>
</ul>
</li>
<li>Aplikácia fasádnej farby pomocou maliarskej techniky podľa výberu materiálu zákazníkom:
<ul>
<li>akrylát</li>
<li>siloxan</li>
<li>silikón</li>
<li>silikát</li>
<li>silikón s uhlíkovým vláknom</li>
<li>silikón s plničom prasklín</li>
</ul> 
</li>
<li>Záverečné čistenie</li>
</ol>
<br />
<h1>Nenechajte to dôjsť tak ďaleko, aby ste museli utrácať peniaze za kompletne novú fasádu!</h1>
<br /><br />
<h3>Požiadajte o cenovú ponuku na náter fasády <b>ZADARMO</b>!</h3>
<br /><br />
Náš technik príde za vami, ZDARMA posúdi stav vašej nehnuteľnosti, navrhne riešenie a po presnom zameraní vypracuje NEZÁVÄZNÚ cenovú ponuku na maľovanie fasády.
<br /><br />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <h2 className="featurette-heading">UPRATOVANIE FIRIEM A PRIEMYSELNÝCH PODNIKOV</h2>
              <div className="lead">
Naša spoločnosť Vám ponúka pravidelné upratovanie podnikov, firiem, administratívnych, obchodných centier, kancelárií a budov štátnej správy. Okrem čistiacich a upratovacích prác Vám vieme zabezpečiť pravidelné zásobovanie hygienickým materiálom.
<ul>
<li>odstraňovanie pavučín</li>
<li>utieranie prachu</li>
<li>čistenie sklenených výplní</li>
<li>vysávanie kobercov</li>
<li>umývanie podláh</li>
<li>umývanie okien</li>
<li>umývanie zrkadiel</li>
<li>umývanie a dezinfekcia vane, sprchy a WC</li>
<li>vynášanie smetí</li>
<li>umývanie a dezinfekcia drezu a kuchynskej linky</li>
<li>umývanie a dezinfekcia elektrických zariadení (chladnička, mikrovlnná rúra atď.)</li>
<li>strojové umývanie podláh</li>
<li>a iné.</li>
</ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <h2 className="featurette-heading">UPRATOVANIE BYTOVÝCH DOMOV</h2>
              <div className="lead">
Ponúkame pravidelné upratovanie spoločných priestorov bytových domov. Jedná sa o údržbové upratovanie spoločných priestorov vrátane výťahu, schodiska a vstupných priestorov. Táto služba obvykle zahŕňa zametanie a umytie priestorov, umytie výťahu a vstupných dverí...
<ul>
<li>odstraňovanie pavučín</li>
<li>utieranie prachu</li>
<li>čistenie sklenených výplní</li>
<li>zametanie podláh</li>
<li>umývanie podláh</li>
<li>umývanie výťahov a jeho sklených výplní</li>
<li>umývanie vstupných dverí vrátane sklených výplní</li>
<li>zametanie pivníc</li>
<li>umývanie okien</li>
<li>umývanie zrkadiel</li>
<li>umývanie a dezinfekcia kľučiek a madiel</li>
<li>a iné.</li>
</ul>
<b>Exteriéry</b><br />
Ponúkame Vám kompletnú starostlivosť o Vaše vonkajšie plochy. Táto služba sa delí na dve časti:
<br />
{/* 1. */} 
Letná údržba exteriérov - Ponúkame starostlivosť o spevnené plochy kde do toho zahŕňame vysýpanie smetí a umývanie popolníkov. Taktiež ponúkame údržbu zelene, kde ponúkame:
<ul>
<li>kosenie</li>
<li>hnojenie</li>
<li>polievanie</li>
<li>technické služby mesta (ostrekovanie a čistenie ulíc a verejných priestranstiev),</li>
<li>sadenie trávnikov.</li>
</ul>
              </div>
            </Col>
          </Row>
          {/* <Row id="kariera" name="kariera" className='mainpart'>
            <Col md="12">
              <h2 className="featurette-heading">UPRATOVANIE DOMÁCNOSTÍ – Doplnková služba</h2>
              <div className="lead">
Ponúkame Vám jednorazové, alebo pravidelné upratanie Vášho domu alebo bytu. 
<ul>
<li>odstraňovanie pavučín</li>
<li>utieranie prachu</li>
<li>čistenie sklenených výplní</li>
<li>vysávanie kobercov</li>
<li>tepovanie kobercov a sedačiek</li>
<li>umývanie podláh</li>
<li>umývanie okien</li>
<li>umývanie zrkadiel</li>
<li>umývanie a dezinfekcia vane, sprchy a WC</li>
<li>vynášanie smetí</li>
<li>umývanie a dezinfekcia drezu a kuchynskej linky</li>
<li>umývanie a dezinfekcia elektrických zariadení (chladnička, mikrovlná rúra atď.).</li>
</ul>
              </div>
            </Col>
          </Row> */}
          <Row>
            <Col md="12">
              <img src={contact} className="col-md-8" alt="logo" />
            </Col>
          </Row>
          <Row id="kontakt" name="kontakt" className='mainpart'>
            <Col md="12">
              <h2 className="featurette-heading">Kontakt</h2>
              <div className="lead">
Ak sme Vás zaujali, nechajte si vypracovať bezplatne cenovú ponuku. Kontaktujte nás telefonicky alebo emailom.
<br />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="lead">
<FaPhone /> Telefón: <a href="tel:00421948285305">+421 948 285 305</a>
<br />
<br />
<FaEnvelope /> Email: <a href="mailto:info@upratovanie.online">info@upratovanie.online</a>
<br />
<br />
              </div>
            </Col>
            <Col md="6">
              <div className="lead">
              <FaMapMarker />
<b>Prevádzka:</b>
<br />
Miletičová 44
<br />
Bratislava 821 08
<br />
<br />
              </div>
            </Col>
            <Col md="6">
              <div className="lead">
<b>Fakturačné údaje:</b>
<br />
POIEL & MARS, s.r.o.
<br />
IČO: 51 421 780
<br />
DIČ: 2120691793
<br />
<br />
              </div>
            </Col>
            <Col md="6">
              <div className="lead">
<b>Bankové údaje:</b>
<br />
Prima Banka
<br />
SWIFT: KOMASK2X
<br />
Číslo účtu: 6754966001/5600
<br />
IBAN: SK17 5600 0000 0067 5496 6001
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col md="12">
            <MyMapComponent
  isMarkerShown
  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
  loadingElement={<div style={{ height: `100%` }} />}
  containerElement={<div style={{ height: `400px` }} />}
  mapElement={<div style={{ height: `100%` }} />}
/>
            </Col>
          </Row> */}
          <Row className='mainpart'>
            <Col md="12">
              <h2 className="featurette-heading">Partner</h2>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <img src={neptun} alt="logo" width='100%' />
            </Col>
            <Col md="4">
            </Col>
            <Col md="2">
              <Button color="danger" href='https://www.neptunsolutions.com/' target='blank'>Viac</Button>
            </Col>
          </Row>
          <footer>
            <Row>
              <Col md="12">
                <p>©{(new Date().getFullYear())} upratovanie.online</p>
              </Col>
            </Row>
          </footer>
        </Container>
      </div>
    );
  }
}

export default App;
